import React from "react"
import { navigate } from "gatsby"
import Layout from '../Layout'
const Negosyo = require('../../../lib/negosyo')

class PublicRoute extends React.Component {
	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===true) {
				//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
				navigate(
					`/my-account`,
					{
						replace:true,
						state: {
							customKey:this.props.location.key
						}
					}
				)
			}
		})//doesn't need error since isLoggedIn won't reject promise but will only resolve with true if logged in or false
	}
	render() {

		const { component: Component, ...rest } = this.props
		const location = this.props.location
		/* Layout must be here or in the component it renders rather than in the
		 * Private/Public Route since if not it won't detect for example the user logging in 
		 * and update the Layout/Header
		 */
		return (
			<Layout location={location}>
				<Component {...rest} />
			</Layout>
		)
	}
}

export default PublicRoute