// extracted by mini-css-extract-plugin
export var backButton = "pages-module--backButton--fa933";
export var buttonicon = "pages-module--buttonicon--48050";
export var centerContent = "pages-module--centerContent--8f864";
export var confirmationButton = "pages-module--confirmationButton--8f0ca";
export var container = "pages-module--container--19535";
export var floatRight = "pages-module--floatRight--d0bd0";
export var form = "pages-module--form--ee711";
export var gridMenu = "pages-module--gridMenu--067b3";
export var header = "pages-module--header--93c4f";
export var icon = "pages-module--icon--82a74";
export var loader = "pages-module--loader--88037";
export var mainButton = "pages-module--mainButton--d37ec";
export var menuItem = "pages-module--menuItem--82951";
export var msg = "pages-module--msg--51a6d";
export var profileGridRow = "pages-module--profileGridRow--8444b";
export var sectionPaddingTop = "pages-module--sectionPaddingTop--ebc32";
export var superHeader = "pages-module--superHeader--fae07";
export var tempDiv = "pages-module--tempDiv--46b5e";
export var text = "pages-module--text--f6875";