import React from "react"
import { Router } from "@reach/router"
import verifyContact from '../components/Pages/verifyContact'
import PublicRoute from '../components/Routes/PublicRoute'

const App = () => (
	<Router>
		<PublicRoute path="/app/verify-contact" component={verifyContact} />
	</Router>
)
export default App