import React from "react"
import { navigate } from 'gatsby'
import Seo from '../SEO'
import SignInOTP from '../Forms/signInOTPChangePassword'
import {Container} from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'


class VerifyContact extends React.Component {

	componentDidMount() {
		//this page can't be accessed directly but only when trying to register as a user that already did a guest checkout
		if(this.props.location.state===null) {
			//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
			navigate(
				`/register/`,
				{
					replace:true,
					state: {
						customKey:this.props.location.key
					}
				}
			)
		}else if( this.props.location.state.contact===undefined ) {
			navigate(
				`/register/`,
				{
					replace:true,
					state: {
						customKey:this.props.location.key
					}
				}
			)
		}
	}
	render() {

		const {
			location
		} = this.props
		return (
			<React.Fragment>
				<Seo title={"Verify Contact"} meta={[{name: `robots`, content: `noindex`}]}/>
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						{location.state && (
							<SignInOTP
								header={"Verify Contact"}
								contact={location.state.contact}
								resendCode={true}
								backFunc={()=>{
									navigate(`/register/`)
								}}
								location={location}
							/>
						)}
					</div>
				</Container>
			</React.Fragment>
		)
	}
}


export default VerifyContact
